import {
  Columns,
  TableOptions,
  operatorId,
  EsDataType,
  recordFields,
} from '../../index';
import { ExternalSystemRecordType } from '@xbcb/shared-types';
import { createTableConfig } from '../createTableConfig';

export const sharedExternalSystemColumns = {
  operatorId,
  name: { default: true, name: 'Name', type: EsDataType.TEXT },
  accountId: {
    hidden: true,
    name: 'Aws Integration AccountId',
    type: EsDataType.KEYWORD,
  },
  ...recordFields(),
};

export const createExternalSystemTableConfig = <TColumns extends Columns>({
  tableOptions = {},
  columns,
}: {
  tableOptions?: TableOptions;
  columns?: TColumns;
}) =>
  createTableConfig({
    columns: {
      ...sharedExternalSystemColumns,
      ...columns,
    },
    tableOptions,
  });

export const externalSystem = createExternalSystemTableConfig({
  tableOptions: { recordTypes: Object.values(ExternalSystemRecordType) },
});
