// Not all tag keys / values are found in these enums but moving forward we
// should add tag keys / values that we plan on reusing / referencing
// throughout our services here, at least where applicable (for example we
// would never be able to add a forwarderId value to `TagValue` but we could
// add `forwarderId`, i.e. `FORWARDER_ID` to `TagKey`). This will ensure we
// have a single source of truth and mitigate the risk of using the incorrect
// hard coded strings everywhere. Additionally, any changes required will mean
// only needing to update the value in one place as lately we have been needing
// to update many places when we decide a tag key / value should change

// NOTE: The enum members do not follow our typical pattern of constantCase
// for both the enum key and value. Instead, we constantCast the enum key but
// allow the enum value to be of any case. This is to be backwards compatible
// as nearly no current tag keys or values are constantCase.

export enum TagKey {
  // This is used to tag teams so we can differentiate which business unit they
  // operator within
  STRATEGIC_BUSINESS_UNIT = 'strategicBusinessUnit',
  FORWARDER_ID = 'forwarderId',
  US_CUSTOMS_BROKER_ID = 'usCustomsBrokerId',
  US_IOR_ACTIVATION_ID = 'usIorActivationId',
  DE_IOR_ACTIVATION_ID = 'deIorActivationId',
  GB_IOR_ACTIVATION_ID = 'gbIorActivationId',
  NL_IOR_ACTIVATION_ID = 'nlIorActivationId',
  FR_IOR_ACTIVATION_ID = 'frIorActivationId',
  US_IOR_ID = 'usIorId',
  DE_IOR_ID = 'deIorId',
  GB_IOR_ID = 'gbIorId',
  NL_IOR_ID = 'nlIorId',
  FR_IOR_ID = 'frIorId',
  US_IOR_CONTINUOUS_BOND_REQUEST_ID = 'usIorContinuousBondRequestId',
  WORK_ORDER_GROUP_ID = 'workOrderGroupId',
  WORK_ORDER_CONFIRMATION_ID = 'workOrderConfirmationId',
  TEMPLATE_DOCUMENT_SIGN_REQUEST_ID = 'templateDocumentSignRequestId',
  PO_NUMBER = 'poNumber',
  TX_ID = 'txId',
  US_IN_BOND_ID = 'usInBondId',
  IN_BOND_NUMBER = 'inBondNumber',
  GB_CUSTOMS_ENTRY_ID = 'gbCustomsEntryId',
  NL_CUSTOMS_ENTRY_ID = 'nlCustomsEntryId',
  FR_CUSTOMS_ENTRY_ID = 'frCustomsEntryId',
  DE_CUSTOMS_ENTRY_ID = 'deCustomsEntryId',
  US_CONSUMPTION_ENTRY_ID = 'usConsumptionEntryId',
  US_TYPE86_ENTRY_ID = 'usType86EntryId',
  CUSTOMS_BROKER_ID = 'customsBrokerId',
  US_ISF_ID = 'usIsfId',
  SHIPPER_ID = 'shipperId',
  LEGACY_ID = 'legacyId',
  INVOICE_ID = 'invoiceId',
  INVOICE_LINES_REQUEST_ID = 'invoiceLinesRequestId',
  SERVICE_PROVIDER_ID = 'serviceProviderId',
  REJECTED = 'rejected',
  AE_TX_ID = 'AeTxId',
  AMAZON_SHIPPER_ID = 'amazonShipperId',
  FLIPPED_FROM_WO_ID = 'flippedFromWoId',
  FLIPPED_TO_WO_ID = 'flippedToWoId',
  CLONED_FROM_WO_ID = 'clonedFromWoId',
  DUPLICATE_WO_ID = 'duplicateWoId',
  NOTIFICATION_TYPE = 'notificationType',
  SOURCE = 'source',
  SKIP_INVOICING = 'skipInvoicing',
  FBA_CONTINUOUS_BOND_BACKFILL = 'fbaContinuousBondBackfill',
  PROGRAM = 'program',
  CONTAINER_ID = 'containerId',
  EXCHANGE_MESSAGE_ID = 'exchangeMessageId',
  IMPORTER_ENTITY_ID = 'importerEntityId',
  IMPORTER_ENTITY_ACTIVATION_ID = 'importerEntityActivationId',
  BOND_NUMBER_ACTIVATION_DATE = 'bondNumberActivationDate',
  DOC_GEN_REQUEST_ID = 'docGenRequestId',
}

// NOTE: To help keep the enums that make up TagValue organized, we can name
// them after the corresponding `TagKey` if it is specific to a certain
// `TagKey`. See `StrategicBusinessUnitTagValue` as an example. But, things
// that can be used across several `TagKey` can simply be added to
// `MultiUseTagValue`

// Used as the tag value for `TagKey.STRATEGIC_BUSINESS_UNIT`
export enum StrategicBusinessUnitTagValue {
  // Represents the FBA team
  AMAZON_GLOBAL_MILE_FORWARDING = 'AmazonGlobalMileForwarding',
  // Represents the non-FBA team
  INLT_EXTERNAL_BUSINESS = 'InltExternalBusiness',
  // Finance team
  CBMS_FINANCE_TEAM = 'CbmsFinanceTeam',
  // Represents the FBA SCOT team (Currently for Customs EU/UK Post-Brexit shipments)
  FBA_SCOT_FORWARDING = 'FbaScotForwarding',
  // Represents Devices Forwarder.
  DEVICES_GLOBAL_LOGISTICS = 'DevicesGlobalLogistics',
  // Represents Retail Forwarder.
  AMAZON_DIRECT_IMPORTS = 'AmazonDirectImports',
  // Represent 1P broker created as part of Wonderwall Project
  WONDERWALL_BROKER = 'WONDERWALL_BROKER',
  // SEND Forwarder.
  SEND_FORWARDING = 'SendForwarding',
}

// Used for more generic TagValues that can be used across multiple different
// TagKeys
export enum MultiUseTagValue {
  CBMS = 'CBMS',
}

// Used for SNS Notification Meta data tags
export enum ExternalSystemNotificationTagValue {
  ENTRY_CONFIRMATION = 'ENTRY_CONFIRMATION',
  SHIPMENT_DOCUMENT_UPLOAD = 'SHIPMENT_DOCUMENT_UPLOAD',
  PENDING_INQUIRY = 'PENDING_INQUIRY',
  CUSTOMS_FILING_MILESTONE = 'CUSTOMS_FILING_MILESTONE',
}

// Used for signifying the business program
export enum ProgramTagValue {
  BREXIT = 'BREXIT',
}

// Here's an explanation of enum merging:
// https://stackoverflow.com/questions/48478361/how-to-merge-two-enums-in-typescript
export const TagValue = {
  ...StrategicBusinessUnitTagValue,
  ...MultiUseTagValue,
  ...ExternalSystemNotificationTagValue,
  ...ProgramTagValue,
};

export type TagValue =
  | StrategicBusinessUnitTagValue
  | MultiUseTagValue
  | ExternalSystemNotificationTagValue
  | ProgramTagValue;
