export enum EntryReferenceType {
  IMPORT_ENTRY_NUMBER = 'IMPORT_ENTRY_NUMBER',
}

export enum EntryReferenceProcessingStatus {
  SUCCESS = 'SUCCESS',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
}

export enum ImportEntryNumberErrorCode {
  INVALID_IEN_FORMAT_EXCEPTION = 'InvalidIenFormatException',
  INVALID_MERCHANT_ID_EXCEPTION = 'InvalidMerchantIdException',
  VALIDATION_EXCEPTION = 'ValidationException',
  INVALID_REQUEST_EXCEPTION = 'InvalidRequestException',
  INTERNAL_SERVICE_EXCEPTION = 'InternalServiceException',
  DEPENDENCY_FAILURE_EXCEPTION = 'DependencyFailureException',
  OPTIMISTIC_LOCK_EXCEPTION = 'OptimisticLockException',
}

export const EntryReferenceErrorCode = {
  ...ImportEntryNumberErrorCode,
};

export type EntryReferenceErrorCode = ImportEntryNumberErrorCode;
