import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserShipperPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserForwarderPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserTruckerPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserFacilityPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserSupplierPermissionName = {
  ...CrudPermissionName,
  UPSERT_CBP_MID_TRANSACTION: 'UPSERT_CBP_MID_TRANSACTION',
};

export const OperatorUserCustomsAgentPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserUsCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserUsConsigneePermissionName = {
  ...CrudPermissionName,
  CREATE_US_CBP_5106_TRANSACTION: 'CREATE_US_CBP_5106_TRANSACTION',
  UPDATE_US_CBP_5106_TRANSACTION: 'UPDATE_US_CBP_5106_TRANSACTION',
  QUERY_US__CBP_TRANSACTION: 'QUERY_US_CBP_TRANSACTION',
};
export const OperatorUserUsIorPermissionName = {
  ...CrudPermissionName,
  CREATE_CBP_5106_TRANSACTION: 'CREATE_CBP_5106_TRANSACTION',
  UPDATE_CBP_5106_TRANSACTION: 'UPDATE_CBP_5106_TRANSACTION',
  QUERY_CBP_TRANSACTION: 'QUERY_CBP_TRANSACTION',
  REQUEST_CBP_ASSIGNED_NUMBER_TRANSACTION:
    'REQUEST_CBP_ASSIGNED_NUMBER_TRANSACTION',
};

export const OperatorUserUkCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserGbConsigneePermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserGbIorPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserDeCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserDeConsigneePermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserDeIorPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserNlCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserNlConsigneePermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserNlIorPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserFrCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserFrConsigneePermissionName = {
  ...CrudPermissionName,
};
export const OperatorUserFrIorPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserCustomsBrokerPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserImporterPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserBrokerPermissionName = {
  ...CrudPermissionName,
};

export const OperatorUserGlobalConsigneePermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.SHIPPER]: OperatorUserShipperPermissionName,
  [ObjectType.FORWARDER]: OperatorUserForwarderPermissionName,
  [ObjectType.TRUCKER]: OperatorUserTruckerPermissionName,
  [ObjectType.FACILITY]: OperatorUserFacilityPermissionName,
  [ObjectType.SUPPLIER]: OperatorUserSupplierPermissionName,
  [ObjectType.CUSTOMS_AGENT]: OperatorUserCustomsAgentPermissionName,
  [ObjectType.US_CONSIGNEE]: OperatorUserUsConsigneePermissionName,
  [ObjectType.US_IOR]: OperatorUserUsIorPermissionName,
  [ObjectType.US_CUSTOMS_BROKER]: OperatorUserUsCustomsBrokerPermissionName,
  [ObjectType.GB_CONSIGNEE]: OperatorUserGbConsigneePermissionName,
  [ObjectType.GB_IOR]: OperatorUserGbIorPermissionName,
  [ObjectType.UK_CUSTOMS_BROKER]: OperatorUserUkCustomsBrokerPermissionName,
  [ObjectType.DE_CONSIGNEE]: OperatorUserDeConsigneePermissionName,
  [ObjectType.DE_IOR]: OperatorUserDeIorPermissionName,
  [ObjectType.DE_CUSTOMS_BROKER]: OperatorUserDeCustomsBrokerPermissionName,
  [ObjectType.NL_CONSIGNEE]: OperatorUserNlConsigneePermissionName,
  [ObjectType.NL_IOR]: OperatorUserNlIorPermissionName,
  [ObjectType.NL_CUSTOMS_BROKER]: OperatorUserNlCustomsBrokerPermissionName,
  [ObjectType.FR_CONSIGNEE]: OperatorUserFrConsigneePermissionName,
  [ObjectType.FR_IOR]: OperatorUserFrIorPermissionName,
  [ObjectType.FR_CUSTOMS_BROKER]: OperatorUserFrCustomsBrokerPermissionName,
  [ObjectType.CUSTOMS_BROKER]: OperatorUserCustomsBrokerPermissionName,
  [ObjectType.IMPORTER]: OperatorUserImporterPermissionName,
  [ObjectType.BROKER]: OperatorUserBrokerPermissionName,
  [ObjectType.GLOBAL_CONSIGNEE]: OperatorUserGlobalConsigneePermissionName,
};
