import { Stage } from '@xbcb/shared-types';

enum CbmsTeams {
  CbmsAbacusOperations = 'amzn1.abacus.team.yucdjf7hspz2ebnvw3uq',
  CbmsCnOperations = 'amzn1.abacus.team.3kb6otwooptmkqv5ixga',
  CbmsData = 'amzn1.abacus.team.b6ob67ygvuwpv3dclpjq',
  CbmsEuUkOperations = 'amzn1.abacus.team.svtv65vmuj64p4bqcifq',
  CbmsProduct = 'amzn1.abacus.team.qud7zt2rtwqo3qtyfvua',
  CbmsReadOnly = 'amzn1.abacus.team.koabk535bks7gx4qgqda',
  CbmsUsOperations = 'amzn1.abacus.team.jxvgw3ciu7p5kbs3thma',
  InltBusinessDevelopment = 'amzn1.abacus.team.hpczf6ddsxf6lzrh6jpa',
  InltFinance = 'amzn1.abacus.team.6g6tiag3o53ggbzg56kq',
  InltTech = 'amzn1.abacus.team.hqvmxdgow6mncml52s5q',
  GmTradeLegal = 'amzn1.abacus.team.3734x2cclpm7mrp4ge6a',
}

// CBMS Teams List https://quip-amazon.com/YulSAjhMYykL/CBMS-Teams-List
export const stageToTeamIdsMapForInternalOperatorUsers = {
  [Stage.ALPHA]: [CbmsTeams.CbmsReadOnly, CbmsTeams.InltTech],
  [Stage.BETA]: [CbmsTeams.CbmsReadOnly, CbmsTeams.InltTech],
  [Stage.BRAVO]: [CbmsTeams.CbmsReadOnly, CbmsTeams.InltTech],
  [Stage.GAMMA]: [
    CbmsTeams.CbmsAbacusOperations,
    CbmsTeams.CbmsCnOperations,
    CbmsTeams.CbmsData,
    CbmsTeams.CbmsEuUkOperations,
    CbmsTeams.CbmsProduct,
    CbmsTeams.CbmsReadOnly,
    CbmsTeams.CbmsUsOperations,
    CbmsTeams.InltBusinessDevelopment,
    CbmsTeams.InltFinance,
    CbmsTeams.InltTech,
    CbmsTeams.GmTradeLegal,
  ],
  [Stage.PROD]: [
    CbmsTeams.CbmsAbacusOperations,
    CbmsTeams.CbmsCnOperations,
    CbmsTeams.CbmsData,
    CbmsTeams.CbmsEuUkOperations,
    CbmsTeams.CbmsProduct,
    CbmsTeams.CbmsReadOnly,
    CbmsTeams.CbmsUsOperations,
    CbmsTeams.InltBusinessDevelopment,
    CbmsTeams.InltFinance,
    CbmsTeams.InltTech,
    CbmsTeams.GmTradeLegal,
  ],
};
